<template>
  <v-dialog
    v-model="dialog"
    width="800"
    scrollable
  >
    <v-card>
      <v-card-title class="pb-2">
        Tipos de incidente
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <!-- creacion  de tipo -->
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" sm="8" class="d-flex align-center py-0">
            <v-text-field
              v-model.trim="nombre"
              label="Nombre"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex align-center justify-end pt-sm-0 pt-3 pb-0">
            <BtnConfirmar
              nombre="Crear"
              :texto="`¿Desea crear el tipo de incidente <strong>${nombre}?</strong>`"
              :disabled="nombre == ''"
              @action="guardar()"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <!-- busqueda de tipos -->
      <v-card-text class="pt-4">
        <v-card flat>
          <!-- filtros -->
          <v-expansion-panels v-model="panel">
            <v-expansion-panel readonly>
              <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
                <div>
                  <v-icon class="mb-1" left>fas fa-filter</v-icon>
                  Filtro
                </div>
                <template v-slot:actions>
                  <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                    <v-icon>fas fa-chevron-down</v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-1">
                <v-form @submit.prevent="buscar()">
                  <v-row>
                    <v-col cols="12" sm="3" md="4" class="py-1">
                      Nombre
                      <v-text-field
                        v-model.trim="filtro.nombre"
                        tabindex="1"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-data-table
            v-model="selected"
            class="cebra elevation-2 mt-2"
            checkbox-color="primary"
            item-key="id"
            :headers="headers"
            :items="tipos"
            :loading="load"
            single-select
            show-select
            dense
          >
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No hay datos para los filtros seleccionados
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4 px-6">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
        <v-btn
          color="success"
          :disabled="selected.length != 1"
          @click="seleccionar(selected[0].id)"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar'
import BtnFiltro from '../util/BtnFiltro'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      nombre: '',
      tipos: [],
      selected: [],
      headers: [
        { text: 'Nombre', value: 'nombre' }
      ],
      filtro: {
        nombre: null
      }
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    BtnFiltro
  },
  watch: {
    dialog (val) {
      if (val) {
        this.nombre = ''
        this.tipos = []
        this.selected = []
        this.limpiar()
      }
    }
  },
  methods: {
    async guardar () {
      this.$store.state.loading = true
      await this.$store.dispatch('incidentes/nuevo_tipo', {
        nombre: this.nombre
      })
        .then((res) => {
          this.seleccionar(res.id)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.nombre) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Ingrese un nombre',
          color: 'orange'
        })
      }
      this.tipos = []
      this.selected = []
      this.load = true
      await this.$store.dispatch('incidentes/buscar_tipos', this.filtro)
        .then((res) => {
          this.tipos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    seleccionar (tipo_id) {
      this.$emit('seleccion', tipo_id)
      this.dialog = false
    },
    limpiar () {
      this.filtro = {
        nombre: null
      }
    }
  }
}
</script>